import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SectionDefault from "../components/sections/section-default"

export default function NotFoundPage() {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <SectionDefault>
        <h1 className="uk-text-center">Oops!</h1>
        <p className="uk-text-center">
          You hit a page that doesn&#39;t exist. Just click the logo to get back
          to the home page
        </p>
        <p className="uk-text-large uk-text-center">¯\_(ツ)_/¯</p>
      </SectionDefault>
    </Layout>
  )
}
